import React, { useState, createContext, useContext, useEffect } from 'react';


const CalendarContext = createContext([])

export const useCalendarContext = () => useContext(CalendarContext)


const CalendarContextProvider = ({ children }) => {


    const [currentMonth, setCurrentMonth] = useState()
    const [daysInCurrentMonth, setDaysInCurrentMonth] = useState()
    const [currentYear, setCurrentYear] = useState()
    const [dateList, setDateList] = useState([])
    const [to, setTo] = useState({})
    const [from, setFrom] = useState({})
    const [daysBooked, setDaysBooked] = useState(Number)
    const [daysBookedCont, setDaysBookedCont] = useState([])
    const [availDays, setAvailDays] = useState([])
    const [availDaysForDetail, setAvailDaysForDetail] = useState([])
    
    function addToDates(newdate) {    // THIS FUNCTION ADD THE NEWDATE TO THE DATELIST
            setDateList(newdate)
    }

 
       function removeSelection() { // THIS FUNCTION REMOVE THE DAYS SELECTION
        setDateList([])
    }

    useEffect(() => {
        function getDaysInMonth(year, month) {
            return new Date(year, month, 0).getDate();
          }
          
          const date = new Date();
          const currentYearF = setCurrentYear(date.getFullYear());
          const currentMonthF = setCurrentMonth(date.getMonth() + 1); // 👈️ months are 0-based
          
          // 👇️ Current Month
          const daysInCurrentMonthF = setDaysInCurrentMonth(getDaysInMonth(currentYear, currentMonth));
  
    }, [from])
    
    const [forbiddenDays, setForbiddenDays] = useState([]);


    const resetForbiddenDays = () => {
      setForbiddenDays([]);
    };

 

    const addForbiddenDay = (day) => {
        setForbiddenDays([...forbiddenDays, day]);
      };

      const resetAvailDaysDetail = () => {
        setAvailDaysForDetail([])
      }
    


    return (
        <CalendarContext.Provider value={{
            addToDates,
            removeSelection,
            dateList,
            daysInCurrentMonth,
            availDays,
            availDaysForDetail,
            resetAvailDaysDetail,
            forbiddenDays,
        addForbiddenDay,
        resetForbiddenDays
        }}>
            {children}

        </CalendarContext.Provider>
    )

}

export default CalendarContextProvider