import React, { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import BookListAddCalendar from './BookListAddCalendar';

export default function BookContAddCalendar({ images, asesoria, toHomeF, selectDate, addToDates }) {

    const db = getFirestore()

    const [serviceChoose, setServiceChoose] = useState(null)


  

    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [firstDay, setFirstDay] = useState("")
    const [monthDays, setMonthDays] = useState()
    const [currentMonth, setCurrentMonth] = useState(null)
    const [serviceYear, setServiceYear] = useState(null)
    const [serviceMonth, setServiceMonth] = useState(null)



    
    useEffect(() => {
        function getDaysInMonth(y, m) {
            return new Date(y, m, 0).getDate();
        }

        const date = new Date();
        const yearF = date.getFullYear()
        const monthF = date.getMonth() + 1

        setYear(yearF)
        setMonth(monthF)
        setCurrentMonth(monthF)

    }, [])


    
    useEffect(() => {

        const yearf = year
        const monthf = month
        const firstDayF = new Date(yearf, monthf - 1, 1)

        setFirstDay(firstDayF.toString()[0] + firstDayF.toString()[1] + firstDayF.toString()[2])
    }, [month])




    useEffect(() => {
        function getDaysInMonth(y, m) {
            return new Date(y, m, 0).getDate();
        }
        setMonthDays(getDaysInMonth(year, month))
    }, [month])
    

    const siguiente = () => {
        setMonth(month + 1)
    
    }

    const anterior = () => {
        setMonth(month - 1)
   
    }

  ////////////////// MONTH BUTTONS

  const monthForward = () => { // THIS FUNCTION

    setMonth(month + 1)

    if (month === 12) {
      setMonth(1)
      setYear(year + 1)
    }
  }
  const monthBackwards = () => {

    const newDay = new Date();

    const currentMonth = newDay.getMonth() + 1;
    const currentYear = newDay.getFullYear();


    setMonth(month - 1)
    if (month === 1) {
      setMonth(12)
      setYear(year - 1)
    } if (month === currentMonth && year === currentYear) {
      setMonth(month)
    }
  }



    return (
        <>
        

<BookListAddCalendar selectDate={selectDate} siguiente={monthForward} anterior={monthBackwards} currentMonth={currentMonth} firstDay={firstDay} month={month} monthDays={monthDays} year={year} serviceChoose={serviceChoose} serviceMonth={serviceMonth}  />
           

         


        </>
    )
}
